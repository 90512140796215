import { API, graphqlOperation } from "aws-amplify";
import { Get_Current_User_Details } from "@/graphql/queries.js";
import { GetDisplayModuleMenu } from "@/mixins/DisplayMenusByUser.js";
import RouterListRSM from "@/JsonFiles/RouterListRSM.json";
import RouterListNSM from "@/JsonFiles/RouterListNSM.json";
import RouterListAgent from "@/JsonFiles/RouterListAgent.json";
import RouterListDealer from "@/JsonFiles/RouterListDealer.json";
import RouterListPartner from "@/JsonFiles/RouterListPartner.json";
import RouterListManager from "@/JsonFiles/RouterListManager.json";
import RouterListAdminOwner from "@/JsonFiles/RouterListAdminOwner.json";
import RouterListManagement from "@/JsonFiles/RouterListManagement.json";
export const GetCurrentUser = {
  mixins: [GetDisplayModuleMenu],
  data() {
    return {
      overlay: false,
      noDataText: "",
      RouterList: [],
      RouterListNSM: [],
      RouterListRSM: [],
      RouterListAgent: [],
      RouterListDealer: [],
      RouterListManager: [],
      RouterListPartner: [],
      RouterListAdminOwner: [],
      RouterListManagement: [],
      get_Current_User_DetailsObject: {},
    };
  },
  methods: {
    async GetCurrentUser() {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(Get_Current_User_Details, {
            input: {
              user_email_id: this.$store.getters.get_user_email,
            },
          })
        );
        this.currentdetails = JSON.parse(
          result.data.Get_Current_User_Details
        ).data.items;
        console.log("this.currentdetails", this.currentdetails);
        this.user_type = JSON.parse(
          result.data.Get_Current_User_Details
        ).data.items[0].user_type;
        this.$store.commit(
          "SET_CURRENTUSER_DETAILS",
          JSON.parse(result.data.Get_Current_User_Details).data.items[0]
        );
        this.$store.commit(
          "SET_USER_TYPE",
          JSON.parse(result.data.Get_Current_User_Details).data.items[0]
            .user_type
        );
        switch (this.user_type) {
          case "OWNER":
            this.RouterList = RouterListAdminOwner;
            if (this.$route.name == "LandingPage") {
              this.$router.push("/Dashboard");
            }
            break;
          case "MANAGEMENT":
            if (
              JSON.parse(result.data.Get_Current_User_Details).data.items[0]
                .organization_details.is_access_control_enable == true
            ) {
              if (
                JSON.parse(result.data.Get_Current_User_Details).data.items[0]
                  .designation_id != undefined
              ) {
                this.$store.commit(
                  "SET_ACCESS_MODULE",
                  JSON.parse(result.data.Get_Current_User_Details).data.items[0]
                    .designation_detail.accessing_module
                );
                this.GetDisplayMenuMethod(
                  JSON.parse(result.data.Get_Current_User_Details).data.items[0]
                    .designation_detail.accessing_module
                );
              } else {
                alert(
                  "Designation is not Updated, Kindly contact Admin to update Designation..!"
                );
                this.$router.push("/");
              }
            } else {
              this.RouterList = RouterListManagement;
              if (this.$route.name == "LandingPage") {
                this.$router.push("/Dashboard");
              }
            }
            break;
          case "NSM":
            if (
              JSON.parse(result.data.Get_Current_User_Details).data.items[0]
                .organization_details.is_access_control_enable == true
            ) {
              if (
                JSON.parse(result.data.Get_Current_User_Details).data.items[0]
                  .designation_id != undefined
              ) {
                this.$store.commit(
                  "SET_ACCESS_MODULE",
                  JSON.parse(result.data.Get_Current_User_Details).data.items[0]
                    .designation_detail.accessing_module
                );
                this.GetDisplayMenuMethod(
                  JSON.parse(result.data.Get_Current_User_Details).data.items[0]
                    .designation_detail.accessing_module
                );
              } else {
                alert(
                  "Designation is not Updated, Kindly contact Admin to update Designation..!"
                );
                this.$router.push("/");
              }
            } else {
              this.RouterList = RouterListNSM;
              if (this.$route.name == "LandingPage") {
                this.$router.push("/Dashboard");
              }
            }
            break;
          case "RSM":
            if (
              JSON.parse(result.data.Get_Current_User_Details).data.items[0]
                .organization_details.is_access_control_enable == true
            ) {
              if (
                JSON.parse(result.data.Get_Current_User_Details).data.items[0]
                  .designation_id != undefined
              ) {
                this.$store.commit(
                  "SET_ACCESS_MODULE",
                  JSON.parse(result.data.Get_Current_User_Details).data.items[0]
                    .designation_detail.accessing_module
                );
                this.GetDisplayMenuMethod(
                  JSON.parse(result.data.Get_Current_User_Details).data.items[0]
                    .designation_detail.accessing_module
                );
              } else {
                alert(
                  "Designation is not Updated, Kindly contact Admin to update Designation..!"
                );
                this.$router.push("/");
              }
            } else {
              this.RouterList = RouterListRSM;
              if (this.$route.name == "LandingPage") {
                this.$router.push("/ActiveTickets");
              }
            }
            break;
          case "ADMIN":
            this.RouterList = RouterListAdminOwner;
            if (this.$route.name == "LandingPage") {
              this.$router.push("/Dashboard");
            }
            break;
          case "MANAGER":
            if (
              JSON.parse(result.data.Get_Current_User_Details).data.items[0]
                .organization_details.is_access_control_enable == true
            ) {
              if (
                JSON.parse(result.data.Get_Current_User_Details).data.items[0]
                  .designation_id != undefined
              ) {
                this.$store.commit(
                  "SET_ACCESS_MODULE",
                  JSON.parse(result.data.Get_Current_User_Details).data.items[0]
                    .designation_detail.accessing_module
                );
                this.GetDisplayMenuMethod(
                  JSON.parse(result.data.Get_Current_User_Details).data.items[0]
                    .designation_detail.accessing_module
                );
              } else {
                alert(
                  "Designation is not Updated, Kindly contact Admin to update Designation..!"
                );
                this.$router.push("/");
              }
            } else {
              this.RouterList = RouterListManager;
              if (this.$route.name == "LandingPage") {
                this.$router.push("/Dashboard");
              }
            }
            break;
          case "AGENT":
            if (
              JSON.parse(result.data.Get_Current_User_Details).data.items[0]
                .organization_details.is_access_control_enable == true
            ) {
              if (
                JSON.parse(result.data.Get_Current_User_Details).data.items[0]
                  .designation_id != undefined
              ) {
                this.$store.commit(
                  "SET_ACCESS_MODULE",
                  JSON.parse(result.data.Get_Current_User_Details).data.items[0]
                    .designation_detail.accessing_module
                );
                this.GetDisplayMenuMethod(
                  JSON.parse(result.data.Get_Current_User_Details).data.items[0]
                    .designation_detail.accessing_module
                );
              } else {
                alert(
                  "Designation is not Updated, Kindly contact Admin to update Designation..!"
                );
                this.$router.push("/");
              }
            } else {
              this.RouterList = RouterListAgent;
              if (this.$route.name == "LandingPage") {
                this.$router.push("/ActiveTickets");
              }
            }
            break;
          case "STORE_MANAGER":
            if (
              JSON.parse(result.data.Get_Current_User_Details).data.items[0]
                .organization_details.is_access_control_enable == true
            ) {
              if (
                JSON.parse(result.data.Get_Current_User_Details).data.items[0]
                  .designation_id != undefined
              ) {
                this.$store.commit(
                  "SET_ACCESS_MODULE",
                  JSON.parse(result.data.Get_Current_User_Details).data.items[0]
                    .designation_detail.accessing_module
                );
                this.GetDisplayMenuMethod(
                  JSON.parse(result.data.Get_Current_User_Details).data.items[0]
                    .designation_detail.accessing_module
                );
              } else {
                alert(
                  "Designation is not Updated, Kindly contact Admin to update Designation..!"
                );
                this.$router.push("/");
              }
            } else {
              this.RouterList = RouterListAgent;
              if (this.$route.name == "LandingPage") {
                this.$router.push("/ActiveTickets");
              }
            }
            break;
          case "SERVICE_PARTNER":
            this.RouterList = RouterListPartner;
            if (this.$route.name == "LandingPage") {
              this.$router.push("/ActiveTickets");
            }
            break;
          case "PARTNER_AGENT":
            this.RouterList = RouterListPartner;
            if (this.$route.name == "LandingPage") {
              this.$router.push("/ActiveTickets");
            }
            break;
          case "DEALER_OWNER":
            this.RouterList = RouterListDealer;
            if (this.$route.name == "LandingPage") {
              this.$router.push("/ActiveTickets");
            }
        }
        this.overlay = false;
      } catch (error) {
        this.noDataText = error.errors[0].message;
        if (
          this.noDataText.includes("Not Found") ||
          this.noDataText.includes("Temporarily Suspended")
        ) {
          this.$router.push("/");
          alert(`${this.noDataText}`);
        }
      }
    },
  },
};

// import { API, graphqlOperation } from "aws-amplify";
// import { Get_Current_User_Details } from "@/graphql/queries.js";
// import { GetDisplayModuleMenu } from "@/mixins/DisplayMenusByUser.js";
// import RouterListRSM from "@/JsonFiles/RouterListRSM.json";
// import RouterListNSM from "@/JsonFiles/RouterListNSM.json";
// import RouterListAgent from "@/JsonFiles/RouterListAgent.json";
// import RouterListDealer from "@/JsonFiles/RouterListDealer.json";
// import RouterListPartner from "@/JsonFiles/RouterListPartner.json";
// import RouterListManager from "@/JsonFiles/RouterListManager.json";
// import RouterListAdminOwner from "@/JsonFiles/RouterListAdminOwner.json";
// import RouterListManagement from "@/JsonFiles/RouterListManagement.json";
// export const GetCurrentUser = {
//   mixins: [GetDisplayModuleMenu],
//   data() {
//     return {
//       overlay: false,
//       noDataText: "",
//       RouterList: [],
//       RouterListNSM: [],
//       RouterListRSM: [],
//       RouterListAgent: [],
//       RouterListDealer: [],
//       RouterListManager: [],
//       RouterListPartner: [],
//       RouterListAdminOwner: [],
//       RouterListManagement: [],
//       get_Current_User_DetailsObject: {},
//     };
//   },
//   methods: {
//     async GetCurrentUser() {
//       try {
//         this.overlay = true;
//         let result = await API.graphql(
//           graphqlOperation(Get_Current_User_Details, {
//             input: {
//               user_email_id: this.$store.getters.get_user_email,
//             },
//           })
//         );
//         this.currentdetails = JSON.parse(
//           result.data.Get_Current_User_Details
//         ).data.items;
//         this.user_type = JSON.parse(
//           result.data.Get_Current_User_Details
//         ).data.items[0].user_type;
//         this.$store.commit(
//           "SET_CURRENTUSER_DETAILS",
//           JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//         );
//         this.$store.commit(
//           "SET_USER_TYPE",
//           JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//             .user_type
//         );
//         switch (this.user_type) {
//           case "OWNER":
//             this.RouterList = RouterListAdminOwner;
//             if (this.$route.name == "LandingPage") {
//               this.$router.push("/Dashboard");
//             }
//             break;
//           case "MANAGEMENT":
//             if (
//               JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                 .organization_details.is_access_control_enable == true
//             ) {
//               if (
//                 JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                   .designation_id != undefined
//               ) {
//                 this.$store.commit(
//                   "SET_ACCESS_MODULE",
//                   JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                     .designation_detail.accessing_module
//                 );
//                 this.GetDisplayMenuMethod(
//                   JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                     .designation_detail.accessing_module
//                 );
//               } else {
//                 alert(
//                   "Designation is not Updated, Kindly contact Admin to update Designation..!"
//                 );
//                 this.$router.push("/");
//               }
//             } else {
//               this.RouterList = RouterListManagement;
//               if (this.$route.name == "LandingPage") {
//                 this.$router.push("/Dashboard");
//               }
//             }
//             break;
//           case "NSM":
//             if (
//               JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                 .organization_details.is_access_control_enable == true
//             ) {
//               if (
//                 JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                   .designation_id != undefined
//               ) {
//                 this.$store.commit(
//                   "SET_ACCESS_MODULE",
//                   JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                     .designation_detail.accessing_module
//                 );
//                 this.GetDisplayMenuMethod(
//                   JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                     .designation_detail.accessing_module
//                 );
//               } else {
//                 alert(
//                   "Designation is not Updated, Kindly contact Admin to update Designation..!"
//                 );
//                 this.$router.push("/");
//               }
//             } else {
//               this.RouterList = RouterListNSM;
//               if (this.$route.name == "LandingPage") {
//                 this.$router.push("/Dashboard");
//               }
//             }
//             break;
//           case "RSM":
//             if (
//               JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                 .organization_details.is_access_control_enable == true
//             ) {
//               if (
//                 JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                   .designation_id != undefined
//               ) {
//                 this.$store.commit(
//                   "SET_ACCESS_MODULE",
//                   JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                     .designation_detail.accessing_module
//                 );
//                 this.GetDisplayMenuMethod(
//                   JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                     .designation_detail.accessing_module
//                 );
//               } else {
//                 alert(
//                   "Designation is not Updated, Kindly contact Admin to update Designation..!"
//                 );
//                 this.$router.push("/");
//               }
//             } else {
//               this.RouterList = RouterListRSM;
//               if (this.$route.name == "LandingPage") {
//                 this.$router.push("/ActiveTickets");
//               }
//             }
//             break;
//           case "ADMIN":
//             this.RouterList = RouterListAdminOwner;
//             if (this.$route.name == "LandingPage") {
//               this.$router.push("/Dashboard");
//             }
//             break;
//           case "MANAGER":
//             if (
//               JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                 .organization_details.is_access_control_enable == true
//             ) {
//               if (
//                 JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                   .designation_id != undefined
//               ) {
//                 this.$store.commit(
//                   "SET_ACCESS_MODULE",
//                   JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                     .designation_detail.accessing_module
//                 );
//                 this.GetDisplayMenuMethod(
//                   JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                     .designation_detail.accessing_module
//                 );
//               } else {
//                 alert(
//                   "Designation is not Updated, Kindly contact Admin to update Designation..!"
//                 );
//                 this.$router.push("/");
//               }
//             } else {
//               this.RouterList = RouterListManager;
//               if (this.$route.name == "LandingPage") {
//                 this.$router.push("/Dashboard");
//               }
//             }
//             break;
//           case "AGENT":
//             if (
//               JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                 .organization_details.is_access_control_enable == true
//             ) {
//               if (
//                 JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                   .designation_id != undefined
//               ) {
//                 this.$store.commit(
//                   "SET_ACCESS_MODULE",
//                   JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                     .designation_detail.accessing_module
//                 );
//                 this.GetDisplayMenuMethod(
//                   JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                     .designation_detail.accessing_module
//                 );
//               } else {
//                 alert(
//                   "Designation is not Updated, Kindly contact Admin to update Designation..!"
//                 );
//                 this.$router.push("/");
//               }
//             } else {
//               this.RouterList = RouterListAgent;
//               if (this.$route.name == "LandingPage") {
//                 this.$router.push("/ActiveTickets");
//               }
//             }
//             break;
//           case "STORE_MANAGER":
//             if (
//               JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                 .organization_details.is_access_control_enable == true
//             ) {
//               if (
//                 JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                   .designation_id != undefined
//               ) {
//                 this.$store.commit(
//                   "SET_ACCESS_MODULE",
//                   JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                     .designation_detail.accessing_module
//                 );
//                 this.GetDisplayMenuMethod(
//                   JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                     .designation_detail.accessing_module
//                 );
//               } else {
//                 alert(
//                   "Designation is not Updated, Kindly contact Admin to update Designation..!"
//                 );
//                 this.$router.push("/");
//               }
//             } else {
//               this.RouterList = RouterListAgent;
//               if (this.$route.name == "LandingPage") {
//                 this.$router.push("/ActiveTickets");
//               }
//             }
//             break;
//           case "SERVICE_PARTNER":
//             if (
//               JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                 .organization_details.is_access_control_enable == true
//             ) {
//               if (
//                 JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                   .designation_id != undefined
//               ) {
//                 this.$store.commit(
//                   "SET_ACCESS_MODULE",
//                   JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                     .designation_detail.accessing_module
//                 );
//                 this.GetDisplayMenuMethod(
//                   JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                     .designation_detail.accessing_module
//                 );
//               } else {
//                 this.RouterList = RouterListPartner;
//                 if (this.$route.name == "LandingPage") {
//                   this.$router.push("/ActiveTickets");
//                 }
//               }
//             } else {
//               this.RouterList = RouterListPartner;
//               if (this.$route.name == "LandingPage") {
//                 this.$router.push("/ActiveTickets");
//               }
//             }
//           case "PARTNER_AGENT":
//             this.RouterList = RouterListPartner;
//             if (this.$route.name == "LandingPage") {
//               this.$router.push("/ActiveTickets");
//             }
//             break;
//           case "DEALER_OWNER":
//             if (
//               JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                 .organization_details.is_access_control_enable == true
//             ) {
//               if (
//                 JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                   .designation_id != undefined
//               ) {
//                 this.$store.commit(
//                   "SET_ACCESS_MODULE",
//                   JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                     .designation_detail.accessing_module
//                 );
//                 this.GetDisplayMenuMethod(
//                   JSON.parse(result.data.Get_Current_User_Details).data.items[0]
//                     .designation_detail.accessing_module
//                 );
//               } else {
//                 this.RouterList = RouterListDealer;
//                 if (this.$route.name == "LandingPage") {
//                   this.$router.push("/ActiveTickets");
//                 }
//               }
//             } else {
//               this.RouterList = RouterListDealer;
//               if (this.$route.name == "LandingPage") {
//                 this.$router.push("/ActiveTickets");
//               }
//             }
//         }
//         this.overlay = false;
//       } catch (error) {
//         this.noDataText = error.errors[0].message;
//         if (
//           this.noDataText.includes("Not Found") ||
//           this.noDataText.includes("Temporarily Suspended")
//         ) {
//           this.$router.push("/");
//           alert(`${this.noDataText}`);
//         }
//       }
//     },
//   },
// };
