import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { Amplify } from "aws-amplify";
import "@/plugins/apexchartsView";
import * as VueGoogleMaps from "vue2-google-maps";
import 'material-design-icons-iconfont/dist/material-design-icons.css'
Vue.use(VueGoogleMaps, {
  load: {
    // key: "AIzaSyBUp8E7OghfEFg9fJ1ePokoOhE1I8MaD-M",
    // key: "AIzaSyDQQR39Enfvo8foNpxMlcRglg03_wxmOv0",
    key: "AIzaSyAuHcsssNtWZhnt0op_n5nm9s8raGeNHGk",
    libraries: "places",
  },
});

Vue.config.productionTip = false
Amplify.configure({
  Auth: {
    userPoolId: "ap-south-1_6WrPu5wo5",
    region: "ap-south-1",
    userPoolWebClientId: "5a6tl1hpfi300iobs1rkf7v66s",
  },
  API: {
    aws_project_region: "ap-south-1",
    aws_appsync_graphqlEndpoint: "https://2bczq22x3rfo7p5rppwn65dcxe.appsync-api.ap-south-1.amazonaws.com/graphql",
    aws_appsync_region: "ap-south-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  },
  endpoints: [
    {
      name: "razorpaypayment",
      endpoint: "https://e60jw0aswc.execute-api.ap-south-1.amazonaws.com/dev",
    },
    {
      name: "SERVICEWRK",
      endpoint: "https://ojnwfkkbma.execute-api.ap-south-1.amazonaws.com/dev/",
    },
    {
      name: "SERVICEWRKQ",
      endpoint: "https://ojnwfkkbma.execute-api.ap-south-1.amazonaws.com/dev/",
    },
    {
      name: "listquerybaseditemsfortabulardata",
      endpoint: "https://ojnwfkkbma.execute-api.ap-south-1.amazonaws.com/dev/",
    },
    {
      name: "BUGTRAKR",
      endpoint: "https://61fvl4pi50.execute-api.ap-southeast-1.amazonaws.com/dev/",
    },
  ],
});
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
